<script>
import Vue from 'vue'
import api from '@/clients/api'
import $ from 'jquery'
import PropertyDetails from './shared/details.vue'
import Spinner from '@/shared/spinner.vue'

import Tabs from '@/shared/tabs.vue'
import PropertyHeader from './header.vue'
import PropertyGeneral from './general.vue'

import ClientCalendar from '@/calendar/ClientCalendar.vue'
import StaffCalendar from '@/calendar/StaffCalendar.vue'

import PropertyQuotes from './quotes.vue'
import PropertyPrivateNotes from './private_notes.vue'

import locking_mixin from '@/shared/locking_mixin'

const ClientCalendarTemplate = {
  name: 'ClientCalendarWrapper',
  components: { ClientCalendar },
  props: ['property', 'readonly', 'goToTab'],
  template: `<client-calendar @go-tab="$emit('go-tab', $event)" :go-to-tab="goToTab" :locationId="property.id"  @visit-notes:updated="$emit('visit-notes:updated')" :readonly="readonly"></client-calendar>`
}

const StaffCalendarTemplate = {
  name: 'StaffCalendarWrapper',
  components: { StaffCalendar },
  props: ['user', 'property', 'readonly'],
  template: `<staff-calendar :client-id="user.id" @go-tab="$emit('go-tab', $event)" :locationId="property.id"   @visit-notes:updated="$emit('visit-notes:updated')" :readonly="readonly"></staff-calendar>`
}

const PropertyVisits = Vue.component('visits-tab', {
  name: 'PropertyVisits',
  functional: true,
  render(h, context) {
    if (context.props.isClient) {
      return h(ClientCalendarTemplate, context.data, context.children)
    } else {
      return h(StaffCalendarTemplate, context.data, context.children)
    }
  }
})

export default {
  mixins: [ locking_mixin ],
  components: {
    PropertyDetails,
    Tabs,
    PropertyHeader,
    PropertyGeneral,
    PropertyQuotes,
    PropertyVisits,
    PropertyPrivateNotes,
    Spinner
  },
  data() {
    return {
      clientId: null,
      loading: false,
      property: null,
      allowedExtraIds: [],
      initTab: '',
      visitTab: '',
      tabs: [],
      tabIdx: 0,
      tabDatas: [],
      hasPendingChanges: false
    }
  },
  created() {
    this.$on('reload', this.loadProperty)
  },
  computed: {
    componentForTab() {
      return 'property-' + this.tab.id
    },
    headerComponent() {
      return 'property-header'
    },
    tab() {
      return this.tabs[this.tabIdx]
    },
    bookPath() {
      return $(this.$el).data('book-path')
    },
    propertyId() {
      if (this.property) {
        return this.property.id
      }
      return $(this.$el).data('id') * 1
    },
    client() {
      return {
        id: this.clientId
      }
    }
  },
  mounted() {
    this.initTab = $(this.$el).data('tab')
    var initVisitId = $(this.$el).data('init-visit-id')
    if (this.$route && this.$route.params.visit_id) {
      initVisitId = this.$route.params.visit_id * 1
    }
    this.loadProperty(initVisitId)
    this.onLocationLockChange(this.propertyId, 'properties/management 103', this.loadProperty)
  },
  methods: {
    loadProperty(initVisitId) {
      this.loading = true
      api.getProperty(this.propertyId).then(response => {
        this.clientId = response.data.client_id
        this.onPropertyLoaded(response)
        if (initVisitId) {
          this.openVisit(initVisitId)
        }
        this.allowedExtraIds = null
        api.getClient(this.clientId).then((response) => {
          this.allowedExtraIds = response.data.allowed_custom_extra_ids
          this.loading = false
        })
      })
    },
    openVisit(visitId) {
      this.goTab(1)
      Window.initVisitId = visitId
    },
    saveExtras({ changedExtras, changes }) {
      this.loading = true
      api.updateProperty(this.propertyId, { extras: JSON.stringify(changedExtras) }).then(response => {
        this.$refs.locker.unlock().then(() => {
          this.loading = false
        })
      })
    },
    updateNotes(newData) {
      var newNotes = this.property.notes
      newNotes[newData.noteKey][newData.fileField] = newData.data
      Vue.set(this.property, 'notes', newNotes)
    },
    onPropertyLoaded(response) {
      this.property = response.data.management.property
      Vue.set(this.property, 'saved_extras', response.data.management.property.saved_extras)
      this.updateLocking({ locked: this.property.locked, readonly: this.property.readonly, lockedBy: this.property.locked_by })
      this.tabs = response.data.management.tabs
      Window.initVisitId = null
    },
    onVisitNotesUpdated() {
      this.loadProperty()
    },
    onReloadProperty() {
      this.loadProperty()
    },
    updateData(id, hours_idx, day_idx) {
      var attrs = {
        days: this.availabilities[hours_idx].days
      };

      var xhr = new XMLHttpRequest()
      xhr.open('PATCH', '/schedule/' + id + this.cleaner_id, true);
      xhr.setRequestHeader("Content-Type", "application/json");
      xhr.setRequestHeader('X-CSRF-Token', $('meta[name="csrf-token"]').attr('content'));

      xhr.send(JSON.stringify(attrs));
    },
    goTab(newTabIdx) {
      this.$refs['tabs'].changeTo(newTabIdx)
    },
    tabChange(tabIdx) {
      Window.initVisitId = null
      this.tabIdx = tabIdx
    },
    bookProperty() {
      window.location = "/properties/"+this.property.id+"/book"
    },
    deleteProperty() {
      this.$dialog
        .confirm('Are you sure you want to delete this property?', { okText: 'Yes', cancelText: 'No' })
        .then((dialog) => {
          api.deleteProperty(this.property.id).then(() => {
            this.$flasher.success('Property successfully deleted')
            window.location = "/admin/clients/" + this.clientId
          }, (errors) => {
           this.$flasher.error(errors.response.data.errors)
          })
        })
      }
  }
}
</script>
