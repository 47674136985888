<template>
  <div class="box-wrapper-in" :css="lockedCss">
    <spinner :show="loading" message="Saving"></spinner>
    <my-form :readonly="readonly" @update:edit-mode="onEditModeChange" success-message="Property updated" ref="form">
      <template v-slot:default="{invalid, valid, formIsReadonly }">
        <admin-address-fields
          :readonly="formIsReadonly"
          :address="propertyAddress"
          @update="onAddressUpdate" />
        <div class="row">
          <label class="col-sm-4 col-form-label">Bedrooms</label>
          <div class="col-sm-8"><bedrooms :readonly="formIsReadonly" :hide-label="true" v-model="bedrooms"/></div>
        </div>
        <div class="row">
          <label class="col-sm-4 col-form-label">Bathrooms</label>
          <div class="col-sm-8"><bathrooms :readonly="formIsReadonly" :hide-label="true" v-model="bathrooms"/></div>
        </div>
        <div class="row">
          <label class="col-sm-4 col-form-label">Area</label>
          <div class="col-sm-8"><area-dropdown :readonly="formIsReadonly" :hide-label="true" v-model="square_feet"/></div>
        </div>
        <template v-if="canImportIcal">
          <div class="row">
            <label class="col-sm-4 col-form-label">ICal URL from Airbnb or Google</label>
            <div class="col-sm-8"><url-field :readonly="formIsReadonly" :hide-label="true" v-model="ical_url" :rules="{ regex: /^https:\/\/www\.airbnb\.com\/calendar\/ical\/\d+\.ics/ }" name="URL" /></div>
          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label">Check out Time</label>
            <div class="col-sm-8"><hours-dropdown :readonly="formIsReadonly" :hide-label="true" v-model="check_out_hour"/></div>
          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label">Check in Time</label>
            <div class="col-sm-8"><hours-dropdown :readonly="formIsReadonly" :hide-label="true" v-model="check_in_hour"/></div>
          </div>
        </template>
        <template v-if="canUpdateServiceArea">
          <div class="row">
            <label class="col-sm-4 col-form-label">Service Area</label>
            <div class="col-sm-8">
              <assigned-service-area v-model="service_area_id" :readonly="formIsReadonly" :hide-label="true" field="service_area_id"></assigned-service-area>
            </div>
          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label">Plan Selection</label>
            <div class="col-sm-8">
              <b-form-checkbox v-if="!formIsReadonly" v-model="free_plan_selection">Allow to Pick Any Plan</b-form-checkbox>
              <label v-else class="col-form-label font-bold">{{free_plan_selection ? 'Allow to Pick Any Plan': 'Normal' }}</label>
            </div>
          </div>
          <div class="row" v-if="!formIsReadonly">
            <div class="alert alert-danger col text-center p-0">Updating the custom prices only affects future, unlocked visits</div>
          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label">Custom Initial Price</label>
            <div class="col-sm-8">
              <amount-field :readonly="formIsReadonly" :hide-label="true" v-model="custom_deep_price" description="Enter 0 to use the price table" />
            </div>
          </div>
          <div class="row">
            <label class="col-sm-4 col-form-label">Custom Upkeep Price</label>
            <div class="col-sm-8">
              <amount-field :readonly="formIsReadonly" :hide-label="true" v-model="custom_basic_price" description="Enter 0 to use the price table" />
            </div>
          </div>
          <div class="row" v-if="!formIsReadonly || showRecurringWarning">
            <div class="alert alert-warning col text-center p-0">Recurring tips are automatically billed for every future cleanings</div>
          </div>
        </template>
        <div class="row">
          <label class="col-sm-4 col-form-label">Recurring Tip Percentage</label>
          <div class="col-sm-8">
            <amount-field :readonly="formIsReadonly || hasTipAmount" :hide-label="true" v-model="recurring_tip_percentage" description="Enter a percentage or 0 to disable the tip" />
            <label v-if="!formIsReadonly && hasTipAmount" class="mt-2">Disable amount to enter a percentage</label>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 col-form-label">Recurring Tip Amount</label>
          <div class="col-sm-8">
            <amount-field :readonly="formIsReadonly || hasTipPercentage" :hide-label="true" v-model="recurring_tip_amount" description="Enter an amount or 0 to disable the tip" />
            <label v-if="!formIsReadonly && hasTipPercentage" class="mt-2">Disable percentage to enter an amount</label>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 col-form-label">Free Parking Available On-Site</label>
          <div class="col-sm-8 form-group">
            <p v-if="formIsReadonly" class="font-bold">{{ has_free_parking ? 'Yes' : 'No' }}</p>
            <label v-else class="checkbox-set">
              <input type="checkbox" v-model="has_free_parking" />
              <span class="checkmark checkmark-blue"></span>
            </label>
          </div>
        </div>
        <div class="row">
          <label class="col-sm-4 col-form-label">Has Pet</label>
          <div class="col-sm-8 form-group">
            <p v-if="formIsReadonly" class="font-bold">{{ has_pet ? 'Yes' : 'No' }}</p>
            <label v-else class="checkbox-set">
              <input type="checkbox" v-model="has_pet" />
              <span class="checkmark checkmark-blue"></span>
            </label>
          </div>
        </div>
      </template>
      <template v-slot:edit-buttons="{ invalid, validated, editMode }">
        <div class="row d-flex justify-content-center align-items-center">
          <div class="text-center my-1">
            <b-button :disabled="invalid && validated" type="submit" variant="mini btn-green my-0">Update</b-button>
          </div>
          <div class="text-center my-1">
            <a class="btn btn-gray my-0" href="#" @click.prevent="$refs.form.cancelEditMode">Cancel</a>
          </div>
        </div>
      </template>
      <template v-slot:readonly-buttons="{ invalid, validated, editMode }">
        <div class="edit-position-absolute edit-property-icon">
          <a v-if="canEditProperty" href="#" @click.prevent="$refs.form.startEditMode">
            <img src="@images/edit-icon02.png" width="40">
          </a>
        </div>
      </template>
    </my-form>
  </div>
</template>
<script>
import Spinner from '@/shared/spinner.vue'
import Vue from 'vue'
// import PropertyUpload from './upload'
import api from '@/clients/api'

import AssignedServiceArea from './assigned_service_area_widget.vue'
import MyForm from '@/shared/base_form.vue'
import MyText from '@/shared/inputs/text.vue'
import PhoneField from '@/shared/fields/phone.vue'
import Bathrooms from '@/shared/fields/bathrooms.vue'
import Bedrooms from '@/shared/fields/bedrooms.vue'
import AreaDropdown from '@/shared/fields/area.vue'
import AmountField from '@/shared/fields/amount.vue'
import HoursDropdown from '@/shared/fields/hours_dropdown.vue'
import UrlField from '@/shared/fields/url.vue'
import AdminAddressFields from '@/shared/admin_address_fields.vue'

export default {
  props: {
    property: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    MyForm, AreaDropdown, Bathrooms, Bedrooms,
    Spinner, UrlField, AssignedServiceArea, AmountField,
    MyText, HoursDropdown, AdminAddressFields
  },
  data() {
    return {
      loading: false,
      submitStatus: null,
      client_phone: null,
      propertyAddress: {},
      bedrooms: null,
      bathrooms: null,
      custom_basic_price: null,
      custom_deep_price: null,
      recurring_tip_percentage: null,
      recurring_tip_amount: null,
      square_feet: null,
      service_area_id: null,
      free_plan_selection: null,
      check_in_hour: null,
      check_out_hour: null,
      ical_url: null,
      has_free_parking: null,
      has_pet: null,
    }
  },
  computed: {
    canEditProperty() {
      if (this.readonly) {
        return false
      }
      return this.isStaff || this.isClient
    },
    canUpdateServiceArea() {
      if (this.readonly) {
        return false
      }
      return this.isGlobalAdmin
    },
    canImportIcal() {
      return this.property.can_import_ical
    },
    hasTipPercentage() {
      return this.recurring_tip_percentage > 0
    },
    hasTipAmount() {
      return this.recurring_tip_amount > 0
    },
    hasRecurringTip() {
      return this.hasTipPercentage || this.hasTipAmount
    },
    lockedCss() {
      if (this.property.locked && this.readonly) {
        return 'locked'
      }
    },
    showAddressLookup() {
      return this.editMode
    },
    showRecurringWarning() {
      return this.hasRecurringTip
    }
  },
  mounted() {
    this.loadValues()
  },
  methods: {
    onAddressUpdate({ field, value }) {
      Vue.set(this.propertyAddress, field, value)
    },
    onEditModeChange(newMode) {
      this.$emit('update:edit-mode', newMode)
      if (newMode === false) {
        this.loadValues()
      }
    },
    loadValues() {
      Vue.set(this.propertyAddress, 'address', this.property.address)
      Vue.set(this.propertyAddress, 'address2', this.property.address2)
      Vue.set(this.propertyAddress, 'city', this.property.city)
      Vue.set(this.propertyAddress, 'state', this.property.state)
      Vue.set(this.propertyAddress, 'country', this.property.country)
      Vue.set(this.propertyAddress, 'zip_code', this.property.zip_code)
      this.bathrooms = this.property.bathrooms
      this.bedrooms = this.property.bedrooms
      this.square_feet = this.property.square_feet
      this.service_area_id = this.property.service_area_ids[0]
      this.free_plan_selection = this.property.free_plan_selection
      this.custom_basic_price = this.property.custom_basic_price
      this.custom_deep_price = this.property.custom_deep_price
      this.recurring_tip_percentage = this.property.recurring_tip_percentage
      this.recurring_tip_amount = this.property.recurring_tip_amount
      this.check_in_hour = this.property.check_in_hour
      this.check_out_hour = this.property.check_out_hour
      this.ical_url = this.property.ical_url
      this.has_free_parking = this.property.has_free_parking
      this.has_pet = this.property.has_pet
    },
    submit() {
      const params = {
        property: {
          address: this.propertyAddress.address,
          address2: this.propertyAddress.address2,
          city: this.propertyAddress.city,
          state: this.propertyAddress.state,
          bathrooms: this.bathrooms,
          bedrooms: this.bedrooms,
          square_feet: this.square_feet,
          zip_code: this.propertyAddress.zip_code,
          service_area_ids: [ this.service_area_id ],
          free_plan_selection: this.free_plan_selection,
          custom_basic_price: this.custom_basic_price,
          custom_deep_price: this.custom_deep_price,
          recurring_tip_percentage: this.recurring_tip_percentage,
          recurring_tip_amount: this.recurring_tip_amount,
          check_in_hour: this.check_in_hour,
          check_out_hour: this.check_out_hour,
          ical_url: this.ical_url,
          has_free_parking: this.has_free_parking,
          has_pet: this.has_pet,
        }
      }
      this.loading = true
      return new Promise((resolve, reject) => {
        api.updateProperty(this.property.id, params.property).then(response => {
          this.$parent.$emit('update:edit-mode', false)
          this.$emit('reload:property')
          this.loading = false
          resolve()
        }, (errors) => {
          this.loading = false
          reject(errors)
        })
      })
    }
  },
  watch: {
    property: {
      deep: true,
      handler: 'loadValues'
    },
    recurring_tip_amount(newValue) {
      if (newValue > 0) {
        this.recurring_tip_percentage = 0
      }
    },
    recurring_tip_percentage(newValue) {
      if (newValue > 0) {
        this.recurring_tip_amount = 0
      }
    }
  }
}
</script>
