<script>
import Base from '@/calendar/Base.vue'

export default {
  name: 'ClientCalendar',
  extends: Base,
  data() {
    return {
      viewMode: 'visits'
    }
  },
  computed: {
    baseUrl() {
      return '/api/calendar'
    }
  },
  methods: {
    loadData() {
      if (this.isVisitsView) {
        console.log("clientCalendar loadData for visitsView not run")
        return
      }
      if (this.isClient && this.isMonthView) {
        console.log("clientCalendar loadData for monthview and staff is running")
        this.loading = false
        Array.prototype.forEach.call(this.daysByWeek, (weeks) => {
          Array.prototype.forEach.call(weeks, (dayData) => {
            dayData.eventData = []
            dayData.loading = true
            this.loadVisitForDate(dayData)
          })
        })
      }
    }
  },
}
</script>
