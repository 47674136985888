<script>
import TextField from '../inputs/text.vue'

export default {
  extends: TextField,
  props: {
    label: {
      type: String,
      default: 'Website'
    },
    name: {
      type: String,
      default: 'website'
    },
    rules: {
      type: String|Object,
      default: 'required|min:3'
    }
  }
}
</script>
