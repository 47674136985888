<template>
  <div class="page-wrapper mt-0">
    <div class="box-wrapper-out">
      <div class="box-wrapper mt-0">
        <img class="img-map-modal-rounded" :src="mapImgSrc">
        <property-details
          @reload:property="onReloadProperty"
          @update:edit-mode="onprUpdateEditMode"
          :property="property"
          :readonly="readonly"
          :edit-mode="editMode" />
      </div>
    </div>
    <div v-html="cleaningPlan"></div>

    <extras
      :can-change-extras="canChangeExtras"
      :can-remove-extras="canChangeExtras"
      :saved-extras="property.saved_extras"
      :allowed-extra-ids="allowedExtraIds"
      @save="onSaveExtras"
      @update:edit-mode="onxUpdateEditMode"
      :property-id="locationId"
      class="box-wrapper mt-4"
      :readonly="readonly" />
    <notes v-if="locationId"
      @update:edit-mode="onUpdateEditMode"
      :location="property"
      context="property-notes"
      @open-visit="$emit('open-visit', $event)"
      @reload:property="onReloadProperty"
      :readonly="readonly" />
    <div class="btn-set btn-set-blue pt-3 mb-2" v-if="canBook && !readonly">
      <button @click="$emit('book')" class="btn btn-primary main-btn mt-4 mx-auto" style="display: table">Make New Booking</button>
      <button @click="$emit('delete')" class="btn-red btn-round cursor-pointer mt-4 mx-auto delete-property-btn"><i class="fas fa-trash-alt"></i></button>
    </div>
  </div>
</template>
<script>
import PropertyDetails from './shared/details.vue'
import Extras from '@/shared/updateable_property_extras.vue'
import Notes from './shared/notes.vue'

export default {
  props: {
    allowedExtraIds: {
      type: Array
    },
    property: {
      type: Object,
      required: true
    },
    readonly: {
      type: Boolean,
      required: true
    },
    lockedCss: {
      type: String
    }
  },
  components: { Notes, PropertyDetails, Extras },
  created() {
    window.addEventListener('beforeunload', this.sendPropertyChangesEmail)
  },
  data() {
    return {
      editMode: false
    }
  },
  computed: {
    canBook() {
      if (this.readonly) {
        return false
      }
      return this.isStaff || this.isClient
    },
    canChangeExtras() {
      if (this.readonly) {
        return false
      }
      return this.isStaff || this.isClient
    },
    locationId() {
      return this.property.id
    },
    noteId() {
      return this.property.notes.id + ''
    },
    notes() {
      return this.property.notes
    },
    mapImgSrc() {
      return this.property.map_img_src
    },
    cleaningPlan() {
      return this.property.cleaning_plan
    },
  },
  methods: {
    onprUpdateEditMode(newMode) {
      this.editMode = newMode
      this.$emit('update:edit-mode', newMode)
    },
    onxUpdateEditMode(n) {
      console.log("extras edit mode changed", n)
    },
    onnUpdateEditMode(n) {
      console.log("notes edit mode changed", n)
    },
    onSaveExtras(params) {
      this.$emit('save-extras', params)
    },
    onReloadProperty() {
      this.$parent.loadProperty()
    },
    onUpdateEditMode(newMode) {
      console.log("prop general update edit mode",newMode)
      this.$emit('update:edit-mode', newMode)
    },
    sendPropertyChangesEmail(e) {
      const url = '/api/properties/' + this.property.id + '/notify'
      this.$http.put(url)
      return true
    }
  }
}
</script>
