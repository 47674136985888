<template>
  <div class="page-wrapper mt-0">
    <div class="alert alert-warning" role="alert">Only staff can see these notes</div>
      <div class="col-12" v-if="!addingNote && !editMode">
        <p class="text-center">
          <b-button class="btn btn-default btn-blue mx-auto" @click="newNote" href="#"><i class="fal fa-plus-circle mr-2"></i> Add Note</b-button>
        </p>
      </div>
      <div v-if="addingNote" class="row justify-content-md-center">
        <div class="col col-md-5 my-4">
          <b-card header-tag="header" footer-tag="footer">
            <div slot="header" class="blue-text">
              <i class="fal fa-plus-circle mr-2"></i> New Note
            </div>
            <b-card-text class="text-center">
              <my-form class="text-center my-0">
                <template v-slot:default="{invalid, valid, errors }">
                  <div class="md-form">
                    <my-textarea class="md-textarea" name="note" v-model="newText" rules="required|min:5"></my-textarea>
                    <b-checkbox v-model="newShowOnVisit">Show On Visit</b-checkbox>
                  </div>
                </template>
                <template v-slot:buttons="{ invalid, validated }">
                  <div class="md-form mt-2">
                    <b-button :disabled="invalid && validated" type="submit" class="btn btn-round btn-green my-0 mx-1 cursor-pointer"><i class="fal fa-check"></i></b-button>
                    <b-button @click="cancel" class="btn btn-round btn-red my-0 mx-1 cursor-pointer"><i class="fal fa-times"></i></b-button>
                  </div>
                </template>
              </my-form>
            </b-card-text>
          </b-card>
        </div>
      </div>
    <div class="row mb-4 justify-content-xs-center" v-for="i in Math.ceil(notes.length / 2)">
      <b-card-group deck class="mx-auto">
        <note v-for="note in notes.slice((i - 1) * 2, i * 2)" :note="note" @update:editmode="editMode=true" @reload="loadNotes" :key="note.id"></note>
      </b-card-group>
    </div>
  </div>
</template>
<script>
import Note from './note.vue'
import api from '@/clients/api'
import MyForm from '@/shared/base_form.vue'
import MyTextarea from '@/shared/inputs/text_area.vue'

export default {
  props: {
    property: {
      type: Object,
      required: true
    }
  },
  components: {
    Note,
    MyForm,
    MyTextarea
  },
  data() {
    return {
      addingNote: false,
      editMode: false,
      newText: '',
      newShowOnVisit: false,
      notes: []
    }
  },
  mounted() {
    this.loadNotes()
  },
  computed: {
    params() {
      return {
        text: this.newText,
        show_on_visit: this.newShowOnVisit
      }
    }
  },
  methods: {
    cancel() {
      this.addingNote = false
      this.newText = ''
    },
    loadNotes() {
      api.getPropertyNotes(this.property.id).then(response => {
        this.notes = response.data
        this.addingNote = false
        this.editMode = false
      })
    },
    newNote() {
      this.addingNote = true
      this.newText = ''
      this.newShowOnVisit = false
    },
    submit() {
      return new Promise((resolve, reject) => {
        api.createPropertyNote(this.property.id, this.params).then(response => {
          this.loadNotes()
          resolve()
        })
      })
    }
  }
}
</script>
