<script>
import Extras from '@/shared/updateable_extras.vue'

export default {
  name: 'UpdateablePropertyExtras',
  extends: Extras,
  props: {
    context: {
      type: String,
      default: 'property-extras'
    },
    propertyId: {
      type: Number,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    showVisitWarning: {
      type: Boolean,
      default: true
    },
    wrapperClass: {
      type: String,
      default: 'box-wrapper-in'
    }
  }
}
</script>
