<template>
  <b-form-group>
    <label v-if="readonly" class="col-form-label font-bold">{{areaName}}</label>
    <dropdown v-else v-model="areaId" :options="options" :show-label="false" :v="v" @input="$emit('change', $event)"></dropdown>
  </b-form-group>
</template>
<script>
import Dropdown from '@/shared/inputs/select.vue'
import api from '@/admins/api'
import { getObjByValue } from '@/utils.js'
export default {
  components: { Dropdown },
  inheritAttrs: false,
  model: {
    prop: 'model',
    event: 'change'
  },
  props: ['model', 'v', 'field', 'readonly'],
  data() {
    return {
      areas: []
    }
  },
  mounted() {
    api.getPickableAreas().then(response => {
      this.areas = response.data
    })
  },
  computed: {
    areaName() {
      if (this.areas && this.areaId) {
        return getObjByValue(this.options, this.areaId).label
      }
      return 'Unserved'
    },
    options() {
      var o = [{label: 'Unserved', value: ''}]
      for (var i = 0; i < this.areas.length; i++) {
        o.push({label: this.areas[i].name, value: this.areas[i].id})
      }
      return o
    },
    areaId: {
      get() {
        return this.model
      },
      set(v) {
        this.$emit('input', v)
      }
    },
  }
}
</script>
