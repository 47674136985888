<template>
  <b-card :title="title" header-tag="header" footer-tag="footer">
    <div slot="header" class="blue-text">
      <i class="fal fa-user mr-2"></i>{{headerText}}
    </div>
    <b-card-text class="text-center">
      <my-form class="text-center my-0">
        <template v-slot:default="{invalid, valid, errors }">
          <div class="md-form">
            <my-textarea v-if="edited" v-model="updatedText" rules="required|min:5" class="md-textarea" name="note" />
            <p v-else>{{note.text}}</p>
            <b-checkbox v-if="edited" v-model="updatedShowOnVisit">Show On Visit</b-checkbox>
            <p v-else class="text-danger">{{updatedShowOnVisit ? 'Visible on Visit' : ''}}</p>
          </div>
        </template>
        <template v-slot:buttons="{ invalid, validated }">
          <div class="md-form mt-2">
            <b-button v-if="!edited" @click="edit" class="btn btn-round btn-green my-0 mx-1 cursor-pointer"><i class="fal fa-edit"></i></b-button>
            <b-button v-if="!edited" @click="deleteNote" class="btn btn-round btn-red my-0 mx-1 cursor-pointer"><i class="fal fa-trash-alt"></i></b-button>
            <b-button v-if="edited" :disabled="invalid && validated" type="submit" class="btn btn-round btn-green my-0 mx-1 cursor-pointer"><i class="fal fa-check"></i></b-button>
            <b-button v-if="edited" @click="cancel" class="btn btn-round btn-red my-0 mx-1 cursor-pointer"><i class="fal fa-times"></i></b-button>
          </div>
        </template>
      </my-form>
    </b-card-text>
    <div v-if="footerText" slot="footer" class="text-muted font-12 text-center">
      <i class="fal fa-clock mr-2"></i>{{footerText}}
    </div>
  </b-card>
</template>
<script>
import api from '@/clients/api'
import MyForm from '@/shared/base_form.vue'
import MyTextarea from '@/shared/inputs/text_area.vue'
export default {
  props: {
    note: {
      type: Object,
      required: true
    }
  },
  components: {
    MyForm,
    MyTextarea
  },
  data() {
    return {
      edited: false,
      updatedText: '',
      updatedShowOnVisit: this.note.show_on_visit
    }
  },
  computed: {
    footerText() {
      return 'Created: ' + this.note.created
    },
    headerText() {
      return 'Edited by ' + this.note.edited_by
    },
    params() {
      return {
        text: this.updatedText,
        show_on_visit: this.updatedShowOnVisit
      }
    },
    title() {
      return ''
    }
  },
  methods: {
    cancel() {
      this.edited = false
    },
    edit() {
      this.edited = true
      this.updatedText = this.note.text
      this.updatedShowOnVisit = this.note.show_on_visit
    },
    submit() {
      return new Promise((resolve, reject) => {
        api.savePropertyNote(this.note.location_id, this.note.id, this.params).then(response => {
          this.edited = false
          this.$emit('reload')
          resolve()
        })
      })
    },
    deleteNote() {
      this.$dialog.confirm('Are you sure you want to delete this note?', {
        okText: 'Yes',
        cancelText: 'No'
      }).then((dialog) => {
        api.deletePropertyNote(this.note.location_id, this.note.id).then(response => {
          this.$emit('reload')
        })
      })
    }
  }
}
</script>
